import { useTranslation } from "react-i18next";
import { useDateHelpers, useRootStore } from "../../../../../../hooks";
import { Card, Collapse, CollapsePanel, Icon, Text, Tooltip } from "../../../../../uiKit";
import { groupingByRoleId, groupingByTags, groupingByType } from "../../helpers/groupingArrayByProperty";
import { IssuesCalendarDialog } from "../issuesCalendarDialog/IssuesCalendarDialog";
import { ICustomIssue, IGroupingList, IPlanningCollapseContentView } from "./PlanningCollapseContent.interface";
import "./PlanningCollapseContent.scss";
import { PlanningCollapseContentTable } from "./planningCollapseContentTable/PlanningCollapseContentTable";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { PlanningCollapseHeader } from "./planningCollapseHeader/PlanningCollapseHeader";
import { timeSpanOperation } from "../../../../../../helpers/dateFunctions";
import { PlaningFilterType } from "../../../../../../constants/PlaningFilterType";
import { ACCEPTED } from "../../../../../../constants/googleCalendarEvent";
import { InternalGoogleEventStatus } from "../../../../../../api/models/CalendarEventDto";

export const PlanningCollapseContentView = memo((props: IPlanningCollapseContentView) => {
  const { t } = useTranslation();

  const typeList = useCallback((type) => {
    return groupingByType(type) ?? [];
  }, []);

  const groupingByRoleList = useMemo(() => {
    const list = groupingByRoleId(props.issuesByCustomType) ?? [];
    return list.map((items) => ({
      name: items[0].role?.name ?? "",
      list: items as ICustomIssue[],
    }))
  }, [props.issuesByCustomType]);

  const groupingByTagsList = useMemo(() => {
    return  groupingByTags(props.issuesByCustomType) ?? [];
  }, [props.issuesByCustomType]);


  const groupingList: IGroupingList[] = useMemo(() => {
    if (props.googleIssues) {
      return [{
        name: t("ui:planning_table.google_events"),
        list: props.googleIssues.map((issue) => ({
          ...issue,
          issue,
          isGoogle: true,
          isDone: issue.googleCalendarData?.internalGoogleEventStatus === InternalGoogleEventStatus.Finished,
        })),
      }]
    }
    console.log("groupingByRoleList", groupingByRoleList);
    return props.groupingType === PlaningFilterType.roles
      ? groupingByRoleList
      : groupingByTagsList;
  }, [groupingByTagsList, groupingByRoleList, props.groupingType, props.googleIssues]);

  const timeFactResult = useCallback((list: ICustomIssue[]) => {
    const timeFactsArr =  list?.filter((i) => !i.isDeleted && !i.isExcludedFromPlan && (i.issue.timeFact ?? "").trim().length > 0)
      .map((i) => i.issue.timeFact);
    return timeSpanOperation().add(timeFactsArr as string[]);
  }, []);

  const timePlanResult = useCallback((list: ICustomIssue[]) => {
    const timePlansArr =  list ?.filter((i) => !i.isDeleted && !i.isExcludedFromPlan && (i.issue.timePlan ?? "").trim().length > 0)
      .map((i) => i.issue.timePlan);
    return timeSpanOperation().add(timePlansArr as string[]);
  }, []);



  const { formatTimeSpan } = useDateHelpers();

  const getTimeFormat = (time: string) => {
    return formatTimeSpan(time, {
      formatWithoutDays: true
    }) || 0;
  }


  return (
    <>
      <IssuesCalendarDialog
        open={props.isOpen}
        onClose={() => props.isOpenChange(false)}
        onTaskCreateClick={props.onIssueToPlanCreate}
        calendarProps={{ initialDate: props.planDateFrom }}
        weekStartDay={props.weekReportStart}
        executorUserId={props.userId}
      />
      <div className="planning-collapse">
        { props.groupingType
          ? groupingList.map((i: IGroupingList, index) => (
            <div
              key={index}
              className="planning-collapse-panel-wrap mb-6"
            >
              <Collapse
                className={"planning-collapse-panel"}
                expandIconPosition="end"
                key={index}
                isNewDesign
              >
                <CollapsePanel
                  key={index}
                  header={
                    <PlanningCollapseHeader
                      issues={i.list}
                      name={i.name}
                      groupingType={props.groupingType ?? PlaningFilterType.roles}
                      isGoogle={!!props.googleIssues}
                    />
                  }
                  className="collapse-panel"
                >
                  <div style={{ overflowX: "auto" }}>
                    {typeList(i.list).map((item: ICustomIssue[]) => {
                      return (
                        <PlanningCollapseContentTable
                          key={props.onIncrementGroupId()}
                          title={props.issueGroupTitle(item?.find((i) => i.type)?.type ?? undefined) ?? ""}
                          issues={item}
                          onIssueDelete={props.onIssueDelete}
                          loadingTaskId={props.loadingTaskId}
                          onIssueEdit={props.onIssueEdit}
                          onLoadRestart={props.onLoadRestart}
                          isOpenChange={props.isOpenChange}
                          planStatus={props.planStatus}
                          isGoogle={!!props.googleIssues}
                          // issuesRestart={props.issuesRestart}
                        />
                      );
                    })}
                  </div>
                </CollapsePanel>
              </Collapse>
              <Card
                variant="transparent"
                className="planing-info-block planing-info-block_transparent"
              >
                <Text className="mr-55">{t("common:planning.summary_by_function")}</Text>
                <div className="planing-info-wrap">
                  <div
                    className={`planing-info-item`}
                  >
                    <div className="planing-info-item__title">
                      {t("ui:time_block.total_tasks")}
                    </div>
                    <div className="planing-info-item__value">
                      <span>{i.list?.filter(({ isDone }) => isDone)?.length ?? 0} </span> / {i.list.length ?? 0}
                    </div>
                  </div>
                  <div
                    className={`planing-info-item`}
                  >
                    <div className="planing-info-item__title">
                      {t("ui:time_block.plan_actual")}
                    </div>
                    <div className="planing-info-item__value">
                            <span>
                              {getTimeFormat(timeFactResult(i.list))}
                            </span>
                      {" / "}
                      {getTimeFormat(timePlanResult(i.list))}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            )
          )
          : (<div className="collapse-panel">
            {
              typeList(props.issuesByCustomType).map((item: ICustomIssue[]) => (
                  <PlanningCollapseContentTable
                    key={props.onIncrementGroupId()}
                    title={props.issueGroupTitle(item?.find((i) => i.type)?.type ?? undefined) ?? ""}
                    issues={item}
                    onIssueDelete={props.onIssueDelete}
                    loadingTaskId={props.loadingTaskId}
                    onIssueEdit={props.onIssueEdit}
                    onLoadRestart={props.onLoadRestart}
                    isOpenChange={props.isOpenChange}
                    planStatus={props.planStatus}
                    // issuesRestart={props.issuesRestart}
                  />
              ))
            }
          </div>
          )
        }
      </div>
    </>
  );
});
