import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IssueDto, IssueScheduleDto } from "../../../../../../api";
import { IdGenerator } from "../../../../../../helpers/idGenerator";
import { useNotifier } from "../../../../../../hooks";
import { api } from "../../../../../../services";
import { ICustomIssue, IPlanningCollapseContent } from "./PlanningCollapseContent.interface";
import "./PlanningCollapseContent.scss";
import { PlanningCollapseContentView } from "./PlanningCollapseContentView";
import { PlanningType } from "./PlanningType";
import { CustomConfirmDialog } from "../../../../dialogs/customConfirmDialog/СustomConfirmDialog";
import { PlanIssueDto } from "../../../../../../api/models/PlanIssueDto";

export const PlanningCollapseContent = memo((props: IPlanningCollapseContent) => {
  const notifier = useNotifier();
  const { t } = useTranslation();
  const [loadingTaskId, setLoadingTaskId] = useState<number | undefined>(undefined);
  const [isOpenCalendarDialog, setIsOpenCalendarDialog] = useState<boolean>(false);

  const groupIdGenerator = new IdGenerator(1);

  const handleIncrementGroupId = useCallback(() => {
    groupIdGenerator.incrementId();
    return groupIdGenerator.getId;
  }, []);

  const issueGroupTitle = useCallback((type: PlanningType | undefined) => {
    if (type == PlanningType.WEEKLY_TASKS) return t("ui:planning_table.weekly_tasks");
    if (type == PlanningType.RECURRING_TASKS) return t("ui:planning_table.recurring_tasks");
    if (type == PlanningType.UNSCHEDULED_TASKS) return t("ui:planning_table.unscheduled_tasks");
  }, []);

  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState<boolean>(false);
  const [removedIssue, setRemovedIssue] = useState<PlanIssueDto | null>(null);
  const handleOpenConfirmDialog = () => setIsShowConfirmDialog(true);
  const handleCloseConfirmDialog = () => setIsShowConfirmDialog(false);

  const handleDeleteIssueFromPlan = useCallback((issue: PlanIssueDto) => {
    if (props.plan) {
      setRemovedIssue(issue)
      handleOpenConfirmDialog();
      // setLoadingTaskId(issueId);

      // setLoadingTaskId(undefined);
    }
  }, [props.plan?.id]);

  const handleClickConfirm = async () => {
    if (props.plan && removedIssue) {
      const resp = await api.plan.deleteIssueFromPlan(props.plan.id!, removedIssue.id)
      if (resp !== null) {
        notifier.show({
          message: t("notifier:success.plan_issue_deleted", {title: removedIssue.name}),
          theme: "success",
        });
        props.onLoadRestart && props.onLoadRestart();
        handleCloseConfirmDialog();
      } else {
        notifier.show({
          message: t("notifier:error.something_wrong"),
          theme: "error",
        });
      }
    } else {
      handleCloseConfirmDialog();
    }
  }

  const getIssueType = useCallback((issue: PlanIssueDto, status: number) => {
    // console.log("issue", issue);
    const { isApproved, isFromRecurringIssue } = issue;
    if (!isApproved && status === 3) {
      return PlanningType.UNSCHEDULED_TASKS;
    }
    if (isFromRecurringIssue) {
      return PlanningType.RECURRING_TASKS;
    }

    return PlanningType.WEEKLY_TASKS;
  }, []);

  const issuesByCustomType = useMemo(() => {
    if (props.googleIssues) {
      return [];
    }
    return [
      ...(props.issues as PlanIssueDto[] ?? []).map((issue: PlanIssueDto) => {
        return {
          issue: {
            ...issue,
            id: issue.id ?? issue.recurringIssueId,
          },
          dateDeadline: issue.dateDeadline,
          role: issue.role,
          roleId: issue.roleId,
          tags: issue.tags,
          type: getIssueType(issue, props.plan?.status ?? 0),
          isDeleted: issue.isDeleted,
          isExcludedFromPlan: issue.isExcludedFromPlan,
          orgchartId: issue.orgchartId,
          isDone: issue.isDone,
        };
      }),
    ];
  }, [props.issues, props.plan?.status]);

  const handleOpenCalendarDialogChange = (isOpen: boolean) => {
    setIsOpenCalendarDialog(isOpen);
  };

  return (
    <>
      {isShowConfirmDialog && (
        <CustomConfirmDialog
          open={true}
          width={645}
          onConfirm={handleClickConfirm}
          onClose={handleCloseConfirmDialog}
          title={t("ui:confirm_dialog_remove_issue_from_plan.title", { title: removedIssue?.name })}
          isNewDesign
        />
      )}
    <PlanningCollapseContentView
      issuesByCustomType={issuesByCustomType as ICustomIssue[]}
      googleIssues={props.googleIssues}
      onIncrementGroupId={handleIncrementGroupId}
      onIssueDelete={handleDeleteIssueFromPlan}
      loadingTaskId={loadingTaskId}
      onIssueEdit={props.onIssueEdit}
      onLoadRestart={props.onLoadRestart}
      isOpen={isOpenCalendarDialog}
      isOpenChange={handleOpenCalendarDialogChange}
      planStatus={props.plan?.status}
      issueGroupTitle={issueGroupTitle}
      onIssueToPlanCreate={props.onIssueToPlanCreate}
      planDateFrom={props.plan?.dateFrom}
      // issuesRestart={props.issuesRestart}
      weekReportStart={props.weekReportStart}
      userId={props.userId}
      groupingType={props.groupingType}
    />
      </>
  );
});
